<template>
  <div 
    :style="{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }"
  >
    <div
      :style="{
        width: '400px',
        maxWidth: '90vw'
      }"
    >
      <h3 class="mb-5">Hai dimenticato la password?</h3>
      <v-text-field
        v-if="!sended"
        solo
        label="Email"
        v-model="email"
        class="mt-4"
        clearable
        append-icon="mdi-email"
        :loading="loading"
      ></v-text-field>
      <div 
        v-else
        class="mt-4 mb-4 text-center"
      >
        abbiamo inviato a <b>{{email}}</b> le istruzioni per il reset.
      </div>
      <v-btn
        v-if="!sended"
        color="primary"
        block
        :loading="loading"
        @click="handleSendClick"
        :disabled="!email"
      >Invia email</v-btn>
      <v-btn
        v-else
        color="primary"
        block
        @click="handleBackToLoginClick"
      >Torna al login</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendResetPassword",
  data: function() {
    return {
      email: undefined,
      loading: false,
      sended: false
    }
  },
  methods: {
    handleSendClick() {
      this.loading = true

      setTimeout(() => {
        this.sended = true
        this.loading = false
      }, 1000)
    },
    handleBackToLoginClick() {
      this.$router.push({
        name: 'Login'
      })
    }
  }
}
</script>

<style>
h3 {
  font-size: 1.2em;
  letter-spacing: -1px;
  font-weight: 700;
  color: black;
  text-align: center;
}
</style>